* {
	font-family: 'Source Sans Pro', sans-serif;
}

button,
input,
textarea {
	outline: none !important;
}

*::-webkit-scrollbar {
	width: 6px !important;
	height: 6px !important;
}
*::-webkit-scrollbar-track {
	background: hsla(0, 0%, 100%, 0.15);
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.35);
}
